import React from "react";

const Canister = () => {
  return (
    <div className="w-full h-full bg-[#008B8B] text-[#fff] sm:pt-[100px] pt-[70px]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center items-center w-full h-full">
        <div className="text-center">
          <h1 className="text-4xl pt-60 sm:pt-0 font-bold inline border-b-4 border-[#ffcba4] text-[#fff]">
            Canister | Mason Jar
          </h1>
          <p className="sm:text-2xl pt-4">
            Availability subject to change, please check{" "}
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
              className="text-[#ffcba4]"
            >
              Etsy
            </a>{" "}
            for current inventory. Visit{" "}
            <a href="/Contact" className="text-[#ffcba4]">
              here
            </a>{" "}
            for custom order requests.
          </p>
        </div>
        {/* Icon Container */}
        <div
          className="w-full grid grid-cols-2 sm:grid-cols-3 gap-4 text-center text-2xl
         py-8"
        >
          {/*Sunny Cowboy Boots Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/336/il/1f9972/4592384882/il_680x540.4592384882_sb5g.jpg"
                alt="Sunny Cowboy Boots Jar"
              />
            </a>
            <p className="py-2">Sunny Cowboy Boots</p>
          </div>
          {/* Native Bear Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2387/1897/0/397/il/6721a3/4640532985/il_680x540.4640532985_furw.jpg"
                alt="Native Bear Canister"
              />
            </a>
            <p className="py-2">Native Bear</p>
          </div>
          {/*Farmhouse Goat Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2102/1671/53/350/il/7f9ee4/4592199250/il_680x540.4592199250_cf0e.jpg"
                alt="Farmhouse Goat Canister"
              />
            </a>
            <p className="py-2">Farmhouse Goat</p>
          </div>
          {/* Winter Gnome Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2163/1719/46/471/il/bddce5/4640452805/il_680x540.4640452805_kgjz.jpg"
                alt="Winter Gnome Vase"
              />
            </a>
            <p className="py-2">Winter Gnome</p>
          </div>
          {/* Honeybee Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2027/1611/147/381/il/68406f/4640454021/il_680x540.4640454021_lzq0.jpg"
                alt="Honeybee Candle Vase"
              />
            </a>
            <p className="py-2">Honeybee Candle Vase</p>
          </div>
          {/*Rustic Metal, Vintage Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/423/il/d0ca93/4640455065/il_680x540.4640455065_5cwe.jpg"
                alt="Hummingbird Sign"
              />
            </a>
            <p className="py-2">Rustic Metal, Vintage</p>
          </div>
          {/*Native Wolf Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/423/il/c3401c/4640594455/il_680x540.4640594455_jt6m.jpg"
                alt="Native Wolf Canister"
              />
            </a>
            <p className="py-2">Native Wolf</p>
          </div>
          {/* Rustic Metal Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/ba9766/4584885840/il_680x540.4584885840_3mf5.jpg"
                alt="Rustic Metal Canister"
              />
            </a>
            <p className="py-2">Rustic Metal, Vintage</p>
          </div>
          {/* Pink Sugar Skull Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/451/il/29f27e/4640656025/il_680x540.4640656025_68nr.jpg"
                alt="Pink Sugar Skull Jar"
              />
            </a>
            <p className="py-2">Pink Sugar Skull</p>
          </div>
          {/* Rustic Metal Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/634/il/b06799/4592212250/il_680x540.4592212250_6ise.jpg"
                alt="Rustic Metal Canister"
              />
            </a>
            <p className="py-2">Rustic Metal, Vintage</p>
          </div>
          {/* Native Fox Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/4399f9/4640555379/il_680x540.4640555379_aac0.jpg"
                alt="Native Fox Canister"
              />
            </a>
            <p className="py-2">Native Fox</p>
          </div>
          {/* Farmhouse Donkey Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2205/1753/18/499/il/039988/4592207504/il_680x540.4592207504_eakl.jpg"
                alt="Native Fox Canister"
              />
            </a>
            <p className="py-2">Farmhouse Donkey</p>
          </div>
          {/*Purple Flower Vase*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2102/1671/71/476/il/a1ad74/4948674582/il_640xN.4948674582_t3vx.jpg"
                alt="Purple Flower Vase Canister"
              />
            </a>
            <p className="py-2">Purple Flower Vase</p>
          </div>
          {/*Blue Owl*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/374/il/cb45c2/4995780213/il_640xN.4995780213_ecrj.jpg"
                alt="Blue Owl Canister"
              />
            </a>
            <p className="py-2">Blue Owl</p>
          </div>
          {/*Honeybee Candle Vase*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/69166f/4991816017/il_640xN.4991816017_ctao.jpg"
                alt="Honeybee Candle Vase"
              />
            </a>
            <p className="py-2">Honeybee Candle Vase</p>
          </div>
          {/*Butterfly Vase*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/480/il/f5a077/4943517428/il_640xN.4943517428_ck8z.jpg"
                alt="Butterfly Vase"
              />
            </a>
            <p className="py-2">Butterfly Vase</p>
          </div>
          {/*DragonFly Vase*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2184/1736/0/541/il/699904/4947432661/il_640xN.4947432661_lwa0.jpg"
                alt="Dragonfly Vase"
              />
            </a>
            <p className="py-2">Dragonfly Vase</p>
          </div>
          {/*Sunflower Gnome*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/5db849/4884212518/il_640xN.4884212518_m106.jpg"
                alt="Dragonfly Vase"
              />
            </a>
            <p className="py-2">Sunflower Gnome</p>
          </div>
          {/*Lucky Frog*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41432669"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/1956/1555/91/610/il/4b7f5f/4592233910/il_640xN.4592233910_qhky.jpg"
                alt="Dragonfly Vase"
              />
            </a>
            <p className="py-2">Lucky Frog</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Canister;
