import React from "react";

const Resin = () => {
  return (
    <div className="w-full h-full bg-[#008B8B] text-[#fff] sm:pt-[100px] pt-[70px]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center items-center w-full h-full">
        <div className="text-center pt-6">
          <h1 className="text-4xl pt-60 sm:pt-0 font-bold inline border-b-4 border-[#ffcba4] text-[#fff]">
            Resin Decor
          </h1>
          <p className="sm:text-2xl pt-4">
            Availability subject to change, please check{" "}
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41510883"
              target="_blank"
              className="text-[#ffcba4]"
            >
              Etsy
            </a>{" "}
            for current inventory. Visit{" "}
            <a href="/Contact" className="text-[#ffcba4]">
              here
            </a>{" "}
            for custom order requests.
          </p>
        </div>
        {/* Icon Container */}
        <div
          className="w-full grid grid-cols-2 sm:grid-cols-3 gap-4 text-center text-2xl
         py-8"
        >
          {/*Blue, Turquoise & Purple Tray Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578913"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/ab7308/4699148828/il_680x540.4699148828_q7c7.jpg"
                alt="Blue, Turquoise & Purple Tray"
              />
            </a>
            <p className="py-2">Blue, Turquoise & Purple Tray</p>
          </div>
          {/* Abalone Tray & Vase Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578913"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2529/2010/313/0/il/439e24/4601406736/il_680x540.4601406736_l0yr.jpg"
                alt="Abalone Tray & Vase"
              />
            </a>
            <p className="py-2">Abalone Tray & Vase</p>
          </div>
          {/* Burgandy, Bronze & Black Tray Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578913"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/846/il/9ab34e/4649461815/il_680x540.4649461815_ckso.jpg"
                alt="Burgandy, Bronze & Black Tray"
              />
            </a>
            <p className="py-2">Burgandy, Bronze & Black Tray</p>
          </div>
          {/* Sugar Skull Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=43169107"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2078/1651/0/701/il/49147f/4992283135/il_640xN.4992283135_q7og.jpg"
                alt="Sugar Skull"
              />
            </a>
            <p className="py-2">Sugar Skull</p>
          </div>
          {/* Sugar Skull Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=43169107"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2008/1596/85/729/il/e42146/4943957902/il_640xN.4943957902_30yv.jpg"
                alt="Sugar Skull"
              />
            </a>
            <p className="py-2">Sugar Skull</p>
          </div>
          {/* Sugar Skull Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=43169107"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2248/1787/240/172/il/ea1711/4943793132/il_640xN.4943793132_8ng8.jpg"
                alt="Sugar Skull"
              />
            </a>
            <p className="py-2">Sugar Skull</p>
          </div>

          {/* Sugar Skull Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=43169107"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/1679/1334/279/789/il/4ee5e2/4991925015/il_640xN.4991925015_63v3.jpg"
                alt="Sugar Skull"
              />
            </a>
            <p className="py-2">Sugar Skull</p>
          </div>
          {/* Mushroom Jar Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=43172123"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2654/2110/340/374/il/c74e1e/5036958072/il_640xN.5036958072_pmjn.jpg"
                alt="Mushroom Jar"
              />
            </a>
            <p className="py-2">Mushroom Jar</p>
          </div>
          {/* Mushroom Jar Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=43172123"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/7abfa5/5036903160/il_640xN.5036903160_bs56.jpg"
                alt="Mushroom Jar"
              />
            </a>
            <p className="py-2">Mushroom Jar</p>
          </div>
          {/* Mushroom Jar Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=43172123"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2777/2207/118/486/il/ee1d8e/5035354675/il_640xN.5035354675_35ga.jpg"
                alt="Mushroom Jar"
              />
            </a>
            <p className="py-2">Mushroom Jar</p>
          </div>
          {/* Mushroom Jar Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=43172123"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2912/2312/43/206/il/282577/5035267449/il_640xN.5035267449_jks0.jpg"
                alt="Mushroom Jar"
              />
            </a>
            <p className="py-2">Mushroom Jar</p>
          </div>
          {/* Mushroom Jar Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=43172123"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/1704/1354/327/728/il/334253/5003668325/il_640xN.5003668325_csr2.jpg"
                alt="Mushroom Jar"
              />
            </a>
            <p className="py-2">Mushroom Jar</p>
          </div>
          {/* Mushroom Jar Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=43172123"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/02938d/5037009970/il_640xN.5037009970_tooq.jpg"
                alt="Mushroom Jar"
              />
            </a>
            <p className="py-2">Mushroom Jar</p>
          </div>
          {/* Mushroom Jar Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=43172123"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/3000/2250/0/0/il/f05245/4992636327/il_640xN.4992636327_nyqb.jpg"
                alt="Mushroom Jar"
              />
            </a>
            <p className="py-2">Mushroom Jar</p>
          </div>
          {/* Mushroom Jar Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=43172123"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2184/1736/18/737/il/f0777a/4944310466/il_640xN.4944310466_5uqr.jpg"
                alt="Mushroom Jar"
              />
            </a>
            <p className="py-2">Mushroom Jar</p>
          </div>
          {/* Mushroom Jar Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=43172123"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/1939/1541/157/629/il/fa05b7/4992533663/il_640xN.4992533663_2ewa.jpg"
                alt="Mushroom Jar"
              />
            </a>
            <p className="py-2">Mushroom Jar</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resin;
