import React from "react";

const Chess = () => {
  return (
    <div className="w-full h-full bg-[#008B8B] text-[#fff] sm:pt-[100px] pt-[70px]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center items-center w-full h-full">
        <div className="text-center">
          <h1 className="text-4xl pt-60 sm:pt-0 font-bold inline border-b-4 border-[#ffcba4] text-[#fff]">
            Chess Sets
          </h1>
          <p className="sm:text-2xl pt-4">
            Availability subject to change, please check{" "}
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
              className="text-[#ffcba4]"
            >
              Etsy
            </a>{" "}
            for current inventory. Visit{" "}
            <a href="/Contact" className="text-[#ffcba4]">
              here
            </a>{" "}
            for custom order requests.
          </p>
        </div>
        {/* Icon Container */}
        <div
          className="w-full grid grid-cols-2 sm:grid-cols-3 gap-4 text-center text-2xl
         py-8"
        >
          {/*Purple Majesty Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-150 mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/8ae6aa/4744219419/il_680x540.4744219419_1e7y.jpg"
                alt="Purple Black Chess Set"
              />
            </a>
            <p className="py-2">Purple Majesty</p>
          </div>
          {/* Aztec Warrior Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-150 mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/8ad039/4716031731/il_680x540.4716031731_qbyb.jpg"
                alt="Aztec Chess Set"
              />
            </a>
            <p className="py-2">Aztec Warrior</p>
          </div>
          {/* Sea Turtle Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-150 mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/163/il/934042/4659095716/il_680x540.4659095716_2e5p.jpg"
                alt="Sea Turtle Chess Set"
              />
            </a>
            <p className="py-2">Sea Turtle</p>
          </div>
          {/* Red Black Gold Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-150 mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/5446ec/4583179914/il_680x540.4583179914_rsgf.jpg"
                alt="Red Black Chess Set"
              />
            </a>
            <p className="py-2">Red, Black, Gold</p>
          </div>
          {/* Emerald */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-150 mx-auto"
                src="https://i.etsystatic.com/36216678/c/2196/1745/516/46/il/aea5bb/4584670668/il_680x540.4584670668_asst.jpg"
                alt="Emerald Chess Set"
              />
            </a>
            <p className="py-2">Emerald</p>
          </div>
          {/* Glow Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-150 mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/db1744/4601103074/il_680x540.4601103074_i0yl.jpg"
                alt="Glow in the Dark Chess Set"
              />
            </a>
            <p className="py-2">Glow in the Dark Blue</p>
          </div>
          {/* Mortality Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-150 mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/a15916/4679501103/il_680x540.4679501103_jwpt.jpg"
                alt="Mortality Chess Set"
              />
            </a>
            <p className="py-2">Mortality</p>
          </div>
          {/*Opal Bliss Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-150 mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/ceb357/4649246597/il_680x540.4649246597_tp9d.jpg"
                alt="Opal Bliss Chess Set"
              />
            </a>
            <p className="py-2">Opal Bliss</p>
          </div>
          {/*American Eagel */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-150 mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/9b42c7/4796020254/il_680x540.4796020254_h1rv.jpg"
                alt="American Eagle Chess Set"
              />
            </a>
            <p className="py-2">American Eagle</p>
          </div>
          {/*Emerald*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-150 mx-auto"
                src="https://i.etsystatic.com/36216678/c/2142/1703/0/576/il/c680e6/5214506268/il_640xN.5214506268_grqw.jpg"
                alt="Emerald Chess Set"
              />
            </a>
            <p className="py-2">Emerald</p>
          </div>
          {/*Elegant White Black*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-150 mx-auto"
                src="https://i.etsystatic.com/36216678/c/1991/1581/127/482/il/a3a3a6/4962536882/il_640xN.4962536882_nuz9.jpg"
                alt="Elegant White, Black & Gold Chess Set"
              />
            </a>
            <p className="py-2">Elegant White, Black & Gold</p>
          </div>
          {/*Crimson*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-150 mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/317/il/ce16c0/4884169690/il_640xN.4884169690_qb1n.jpg"
                alt="Crimson Chess Set"
              />
            </a>
            <p className="py-2">Crimson</p>
          </div>
          {/*Tranquility*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/b0c0d1/4883926434/il_794xN.4883926434_d5oa.jpg"
                alt="Tranquility Chess Set"
              />
            </a>
            <p className="py-2">Tranquility</p>
          </div>
          {/*Bubble Gum*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/346/il/089733/4883790330/il_640xN.4883790330_hn4h.jpg"
                alt="Bubble Gum Chess Set"
              />
            </a>
            <p className="py-2">Bubble Gum</p>
          </div>
          {/*Sugar Skull*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2857/2271/0/293/il/5fd964/5365161041/il_640xN.5365161041_sec2.jpg"
                alt="Sugar Skull Chess Set"
              />
            </a>
            <p className="py-2">Sugar Skull</p>
          </div>
          {/*Spirit Wolf*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/1991/1582/0/536/il/c44306/5365016695/il_640xN.5365016695_97us.jpg"
                alt="Spirit Wolf Chess Set"
              />
            </a>
            <p className="py-2">Spirit Wolf</p>
          </div>
          {/*Tranquility 2*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2912/2315/0/298/il/3c7fd1/5262621579/il_640xN.5262621579_30if.jpg"
                alt="Tranquility 2 Chess Set"
              />
            </a>
            <p className="py-2">Tranquility II</p>
          </div>
          {/*Chinese Dragon*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/fb33a4/5512451402/il_794xN.5512451402_6egz.jpg"
                alt="Chinese Dragon Chess Set"
              />
            </a>
            <p className="py-2">Chinese Dragon</p>
          </div>
          {/*Samurai*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/30dc15/5512375284/il_794xN.5512375284_96fz.jpg"
                alt="Samurai Chess Set"
              />
            </a>
            <p className="py-2">Samurai</p>
          </div>
          {/*Outback*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=41423895"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/73850d/5512292432/il_794xN.5512292432_8jgc.jpg"
                alt="Outback Chess Set"
              />
            </a>
            <p className="py-2">Outback</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chess;
