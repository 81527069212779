import React from "react";
import Bronze from "../assets/Bronze.jpg";
import Gold from "../assets/Gold.JPG";
import GoldBling from "../assets/GoldBling.jpg";
import PurpleBling from "../assets/PurpleBling.JPG";
import RedBling from "../assets/RedBling.jpg";
import RedMarble from "../assets/RedMarble.JPG";
import Stardust from "../assets/Stardust.jpg";
import Teal from "../assets/Teal.JPG";
import TealBling from "../assets/TealBling.JPG";
import White from "../assets/White.JPG";
import Enchanted from "../assets/Enchanted.JPG";

const Charcuterie = () => {
  return (
    <div className="w-full h-full bg-[#008B8B] text-[#fff] sm:pt-[100px] pt-[70px]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center items-center w-full h-full">
        <div className="text-center">
          <h1 className="text-4xl pt-60 sm:pt-0 font-bold inline border-b-4 border-[#ffcba4] text-[#fff]">
            Charcuterie Boards
          </h1>
          <p className="sm:text-2xl pt-4">
            Availability subject to change, please check{" "}
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie"
              target="_blank"
              className="text-[#ffcba4]"
            >
              Etsy
            </a>{" "}
            for current inventory. Visit{" "}
            <a href="/Contact" className="text-[#ffcba4]">
              here
            </a>{" "}
            for custom order requests.
          </p>
        </div>
        {/* Icon Container */}
        <div
          className="w-full grid grid-cols-2 sm:grid-cols-3 gap-4 text-center text-2xl
         py-8"
        >
          {/*Spirit of the Sea Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578941"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/4df862/5034377824/il_794xN.5034377824_1pvu.jpg"
                alt="Spirit of the Sea Charcuterie Board"
              />
            </a>
            <p className="py-2">Spirit of the Sea</p>
          </div>
          {/*Ocean Vibe Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578941"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2399/1906/92/604/il/92e51d/5034288380/il_640xN.5034288380_k739.jpg"
                alt="Spirit of the Sea Charcuterie Board"
              />
            </a>
            <p className="py-2">Ocean Vibe</p>
          </div>
          {/*Gold Rush Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578941"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2857/2271/109/341/il/d640ec/5034215094/il_640xN.5034215094_svpa.jpg"
                alt="Gold Rush Charcuterie Board"
              />
            </a>
            <p className="py-2">Gold Rush</p>
          </div>
          {/*Berries & Cream Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578941"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2857/2271/0/219/il/ff545b/5082351535/il_640xN.5082351535_q9gq.jpg"
                alt="Berries & Cream Charcuterie Board"
              />
            </a>
            <p className="py-2">Berries & Cream</p>
          </div>
          {/*Bronze Storm Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578941"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2752/2185/173/452/il/dff594/5082297971/il_640xN.5082297971_m2rx.jpg"
                alt="Bronze Storm Charcuterie Board"
              />
            </a>
            <p className="py-2">Bronze Storm</p>
          </div>
          {/*Blue Iris Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578941"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/1991/1582/25/621/il/0a06a4/4989308620/il_640xN.4989308620_m6oc.jpg"
                alt="Blue Iris Charcuterie Board"
              />
            </a>
            <p className="py-2">Blue Iris</p>
          </div>

          {/*Enchanted Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578941"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/519/il/b6040d/5037507811/il_640xN.5037507811_hd8t.jpg"
                alt="Enchanted Charcuterie Board"
              />
            </a>
            <p className="py-2">Enchanted</p>
          </div>
          {/*Emerald Isle Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578941"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/2d97dd/4989341606/il_640xN.4989341606_getg.jpg"
                alt="Enchanted Charcuterie Board"
              />
            </a>
            <p className="py-2">Emerald Isle</p>
          </div>
          {/*Abstract Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578941"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/b6c928/5037608461/il_640xN.5037608461_5ghv.jpg"
                alt="Abstract Charcuterie Board"
              />
            </a>
            <p className="py-2">Abstract</p>
          </div>
          {/*Smoke Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578941"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/3000/2384/0/166/il/a83f9b/4989227326/il_640xN.4989227326_er6t.jpg"
                alt="Abstract Charcuterie Board"
              />
            </a>
            <p className="py-2">Smoke</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charcuterie;
