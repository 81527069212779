import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Home from "./components/Home";
import About from "./components/About";
import Lazy from "./components/Lazy";
import Welcome from "./components/Welcome";
import Chess from "./components/Chess";
import Canister from "./components/Canister";
import Jewelry from "./components/Jewelry";
import Resin from "./components/Resin";
import Krafts from "./components/Krafts";
import Charcuterie from "./components/Charcuterie";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Lazy" element={<Lazy />} />
        <Route path="/Welcome" element={<Welcome />} />
        <Route path="/Chess" element={<Chess />} />
        <Route path="/Canister" element={<Canister />} />
        <Route path="/Jewelry" element={<Jewelry />} />
        <Route path="/Resin" element={<Resin />} />
        <Route path="/Krafts" element={<Krafts />} />
        <Route path="/Charcuterie" element={<Charcuterie />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
