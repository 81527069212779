import React from "react";

const Lazy = () => {
  return (
    <div className="w-full h-full bg-[#008B8B] text-[#fff] sm:pt-[100px] pt-[70px]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center items-center w-full h-full">
        <div className="text-center">
          <h1 className="text-4xl pt-60 sm:pt-0 font-bold inline border-b-4 border-[#ffcba4] text-[#fff]">
            Lazy Susans
          </h1>
          <p className="sm:text-2xl pt-4">
            Availability subject to change, please check{" "}
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie"
              target="_blank"
              className="text-[#ffcba4]"
            >
              Etsy
            </a>{" "}
            for current inventory. Visit{" "}
            <a href="/Contact" className="text-[#ffcba4]">
              here
            </a>{" "}
            for custom order requests.
          </p>
        </div>

        {/* Icon Container */}
        <div
          className="w-full grid grid-cols-2 sm:grid-cols-3 gap-4 text-center text-2xl
         py-8"
        >
          {/*Mars Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/769/il/c246dd/5049126292/il_640xN.5049126292_gc23.jpg"
                alt="MARS Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Mars</p>
          </div>
          {/*Liquid Blues Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/1973/1568/210/742/il/cc79b8/5048990016/il_640xN.5048990016_arqj.jpg"
                alt="LIQUID BLUES Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Liquid Blues</p>
          </div>
          {/*Tranquility Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/3000/2384/0/320/il/2aa33b/5082398555/il_640xN.5082398555_oq76.jpg"
                alt="Tranquility Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Tranquility</p>
          </div>
          {/*Snow Storm Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/1859/1476/249/776/il/7f7b2c/5032385454/il_640xN.5032385454_n4oc.jpg"
                alt="The Blues Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Snow Storm</p>
          </div>
          {/*Enchanted Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1786/0/680/il/a3a9f8/5001225158/il_640xN.5001225158_9kt4.jpg"
                alt="Enchanted Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Enchanted</p>
          </div>
          {/*Spirit of the Sea Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/2172ae/5278992529/il_640xN.5278992529_buh7.jpg"
                alt="Spirit of the Sea Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Spirit of the Sea</p>
          </div>
          {/*Fools Gold Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2213/1759/274/491/il/7999b3/4933494857/il_640xN.4933494857_d2oy.jpg"
                alt="Fools Gold Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Fools Gold</p>
          </div>
          {/*Arctic Ice Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/2250/1788/0/836/il/c36941/4858157988/il_640xN.4858157988_a7gn.jpg"
                alt="Arctic Ice Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Arctic Ice</p>
          </div>
          {/*Pink Coral Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/bf47b0/5367587928/il_640xN.5367587928_ry2r.jpg"
                alt="Pink Coral Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Pink Coral</p>
          </div>
          {/*Metalic Yin Yang Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/31eb72/5278855537/il_640xN.5278855537_bywc.jpg"
                alt="Metalic Yin Yang Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Metalic Yin Yang</p>
          </div>
          {/*Precious Metals */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/991f2f/5367621102/il_640xN.5367621102_om4d.jpg"
                alt="Precious Metals Lazy Susan"
              />
            </a>
            <p className="py-2">Precious Metals</p>
          </div>
          {/*Abalone*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/5d7313/5365267561/il_640xN.5365267561_kius.jpg"
                alt="Abalone Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Abalone</p>
          </div>
          {/*Cosmic Storm*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/f4cb27/5317062924/il_640xN.5317062924_70lh.jpg"
                alt="Cosmic Storm Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Cosmic Storm</p>
          </div>
          {/*Amythest*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/1fd63e/5260202906/il_640xN.5260202906_cwjj.jpg"
                alt="Amythest Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Amythest</p>
          </div>
          {/*Lunar Eclipse*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/19c9f2/5214358544/il_640xN.5214358544_4sjn.jpg"
                alt="Lunar Eclipse Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Lunar Eclipse</p>
          </div>
          {/*Persian Mist*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/2b8208/5156894487/il_640xN.5156894487_c9wd.jpg"
                alt="Persian Mist Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Persian Mist</p>
          </div>
          {/*Purple Passion*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/b2992c/5560696207/il_794xN.5560696207_dylr.jpg"
                alt="Purple Passion Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Purple Passion</p>
          </div>
          {/*Gold Cosmic Storm*/}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578933"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/3d601e/5560658603/il_794xN.5560658603_hs2b.jpg"
                alt="Gold Cosmic Storm Resin Lazy Susan"
              />
            </a>
            <p className="py-2">Gold Cosmic Storm</p>
          </div>
          {/*Butterfly Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578913"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/b28a52/4083449145/il_680x540.4083449145_eic3.jpg"
                alt="Butterfly Lazy Susan"
              />
            </a>
            <p className="py-2">Butterfly</p>
          </div>
          {/* Moose Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578913"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/3000/2250/0/0/il/68d547/4035775164/il_680x540.4035775164_2hmk.jpg"
                alt="Rustic Moose Lazy Susan"
              />
            </a>
            <p className="py-2">Rustic Moose</p>
          </div>
          {/* Wolf Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578913"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/r/il/05c3b1/4796347568/il_680x540.4796347568_3vmm.jpg"
                alt="Rustic Wolf Lazy Susan"
              />
            </a>
            <p className="py-2">Rustic Wolf</p>
          </div>
          {/* React Icon */}
          <div>
            <a
              href="https://www.etsy.com/shop/KnicKnacksByJennie?section_id=38578913"
              target="_blank"
            >
              <img
                className="w-[300px] sm:h-[250px] h-[150px] mx-auto"
                src="https://i.etsystatic.com/36216678/c/3000/2250/0/0/il/a55261/4027025314/il_680x540.4027025314_c0sh.jpg"
                alt="Rustic Bear Lazy Susan"
              />
            </a>
            <p className="py-2">Rustic Bear</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lazy;
